export const PRIVATE_CABINET_SLICE = {
  title: 'Private cabinet',
  usersTitle: 'User list',
  addUsersButton: '+ Add user',
  userStatus: 'Status: {{status}}',
  activeStatus: 'active',
  inactiveStatus: 'deactivated',
  updatePasswordButton: 'Update password',
  editButton: 'Edit',
  headsetsTitle: 'Headset list:',
  addHeadsetButton: '+ Add headset',
  userHeadset: "(the user's headset)",
  myHeadser: 'my headset',
  goToMeetingButton: 'Go to conference',
  dataOnHeadsetButton: 'Data from headset',
};
