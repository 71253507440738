export const MEETING_SLICE = {
  headerTitle: 'Headset conference:',
  you: 'You',
  off: 'Turn off',
  on: 'Turn on',
  wrap: 'Minimize',
  unwrap: 'Maximize',
  show: 'Show',
  hidden: 'Hide',
  toggleMic: '{{status}} microphone',
  toggleVideo: '{{status}} video',
  micNotFound: 'Microphone not available',
  videoNotFound: 'Camera not available',
  audioinput: 'Audio recording device',
  videoinput: 'Video recording device',
  roles: {
    engineer: {
      title: 'In the engineers’ room: {{count}}',
      buttonTitle: 'Enter as engineer',
      description: `Only one engineer can enter, engineers earn bonuses, description of the rights and possibilities of an engineer`,
    },
    expert: {
      title: 'In the experts’ room: {{count}}',
      buttonTitle: 'Enter as expert',
      description: `Only one expert can enter, experts earn bonuses, description of the rights and possibilities of an expert`,
    },
    observer: {
      title: 'In the observers’ room: {{count}}',
      buttonTitle: 'Enter as observer',
      description: `Up to three observers can enter, observers do not receive bonuses, description of the rights and possibilities of observers`,
    },
    security: {
      title: 'In the security room: {{count}}',
      buttonTitle: 'Enter as security',
      description: `Only one security personnel can enter, security personnel do not receive bonuses, description of the rights and possibilities of security personnel`,
    },
    headset: {
      buttonTitle: 'Log in as a headset',
      description: `Some description of the headset's role in the room`,
    },
  },
  failedBanner:
    'No access to camera and microphone,\nconnect them or grant permission for their use,\nand then refresh the page',
  continueConference: 'Continue conference',
  controls: {
    arrow: {
      up: 'Up',
      down: 'Down',
      left: 'Left',
      right: 'Right',
    },
    chat: 'Chat',
    exit: 'Exit',
    translation: '{{status}} translation',
    fix: '{{status}} hold view',
    light: '{{status}} flashlight',
    video: '{{status}} video',
    laser: '{{status}} laser',
    mic: '{{status}} microphone',
    record: '{{status}} record room',
    videoFurther: '{{status}} restrict video further',
    visualEffects: '{{status}} visual effects',
    people: 'People list',
    snapshot: 'Take snapshot',
    optionstTitle: 'Settings',
    options: {
      mouse_sensitivity: 'Mouse sensitivity:',
      keyboard_sensitivity: 'Keyboard sensitivity:',
      depth_view: 'Restrict video further:',
    },
  },
};
