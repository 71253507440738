import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useGetMe } from 'api';
import dateAndTime from 'date-and-time';
import { Socket } from 'socket.io-client';
import { useIntersection } from 'hooks';
import { SocketMessage } from '../messenger';
import { Message } from './';
import style from './styles.module.scss';

export type TypeMessage = {
  id: number;
  text: string;
  timestamp: number;
  username: string;
  attachments: {
    name: string;
    url: string;
    type: 'file' | 'image';
  }[];
};

type MessagesBlockProps = {
  socket: Socket;
  socketEvent: SocketMessage | null;
  chatId: string;
};

export const MessagesBlock: FC<MessagesBlockProps> = ({ socket, socketEvent, chatId }) => {
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const messagesBlockRef = useRef<HTMLDivElement>(null);
  const { data: user } = useGetMe();
  const [messages, setMessages] = useState<TypeMessage[]>([]);
  const lastIndex = messages.length - 1;

  const isVisible = useIntersection(lastMessageRef, { root: messagesBlockRef.current });

  const handleGetPreviousMessages = () => {
    const { id } = messages[lastIndex];
    socket.emit('history', id);
  };

  useEffect(() => {
    if (isVisible) {
      handleGetPreviousMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    setMessages([]);
  }, [chatId]);

  useEffect(() => {
    if (!socketEvent) return;
    const { type, e } = socketEvent;

    if (type === 'message') {
      setMessages(state => [e, ...state]);
    } else if (type === 'messages') {
      const newState = [...messages, ...e];
      const uniqueArray: TypeMessage[] = [];
      const ids = new Set();

      newState.forEach(item => {
        if (!ids.has(item.id)) {
          uniqueArray.push(item);
          ids.add(item.id);
        }
      });
      setMessages(uniqueArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketEvent]);

  const display = useMemo(
    () =>
      messages.map((item, index) => {
        const { id, text, username, timestamp, attachments } = item;
        const isMyMessage = username === user?.username;
        const date = new Date(timestamp * 1000);
        const time = dateAndTime.format(date, 'HH:mm');
        const isLastMessage = lastIndex === index;
        const ref = isLastMessage ? lastMessageRef : undefined;

        return (
          <Message
            lastMessageRef={ref}
            key={id}
            isMyMessage={isMyMessage}
            text={text}
            time={time}
            attachments={attachments}
          />
        );
      }),
    [messages, lastIndex, user],
  );
  return (
    <div className={style.messagesBlock} ref={messagesBlockRef}>
      <div className={style.scrollBlock}>{display}</div>
    </div>
  );
};
