export const MEETING_SLICE = {
  headerTitle: '耳机会议：',
  you: '你',
  off: '关闭',
  on: '开启',
  wrap: '最小化',
  unwrap: '最大化',
  show: '显示',
  hidden: '隐藏',
  toggleMic: '{{status}} 麦克风',
  toggleVideo: '{{status}} 视频',
  micNotFound: '麦克风不可用',
  videoNotFound: '摄像头不可用',
  audioinput: '音频录制设备',
  videoinput: '视频录制设备',
  roles: {
    engineer: {
      title: '工程师房间内：{{count}}',
      buttonTitle: '以工程师身份进入',
      description: '只能有一名工程师进入，工程师可获得奖金，工程师的权利和可能性描述',
    },
    expert: {
      title: '专家房间内：{{count}}',
      buttonTitle: '以专家身份进入',
      description: '只能有一名专家进入，专家可获得奖金，专家的权利和可能性描述',
    },
    observer: {
      title: '观察者房间内：{{count}}',
      buttonTitle: '以观察者身份进入',
      description: '最多三名观察者可以进入，观察者不获得奖金，观察者的权利和可能性描述',
    },
    security: {
      title: '安保房间内：{{count}}',
      buttonTitle: '以安保身份进入',
      description: '只能有一名安保人员进入，安保人员不获得奖金，安保人员的权利和可能性描述',
    },
    headset: {
      buttonTitle: '以耳机身份登录',
      description: `耳机在房间中的作用的一些描述`,
    },
  },
  failedBanner: '无法访问摄像头和麦克风，请连接它们或授权使用，然后刷新页面',
  continueConference: '继续会议',
  controls: {
    arrow: {
      up: '上',
      down: '下',
      left: '左',
      right: '右',
    },
    chat: '聊天',
    exit: '退出',
    translation: '{{status}} 翻译',
    fix: '{{status}} 保持视角',
    light: '{{status}} 手电筒',
    video: '{{status}} 视频',
    laser: '{{status}} 激光',
    mic: '{{status}} 麦克风',
    record: '{{status}} 录制房间',
    videoFurther: '{{status}} 进一步限制视频',
    visualEffects: '{{status}} 视觉效果',
    people: '人员列表',
    snapshot: '拍摄快照',
    optionstTitle: '设置',
    options: {
      mouse_sensitivity: '鼠标灵敏度：',
      keyboard_sensitivity: '键盘灵敏度：',
      depth_view: '进一步限制视频：',
    },
  },
};
