export const PRIVATE_CABINET_SLICE = {
  title: 'Личный кабинет',
  usersTitle: 'Список пользователей',
  addUsersButton: '+ Добавить пользователя',
  userStatus: 'Статус: {{status}}',
  activeStatus: 'активный',
  inactiveStatus: 'выключен',
  updatePasswordButton: 'Обновить пароль',
  editButton: 'Редактировать',
  headsetsTitle: 'Список гарнитур:',
  addHeadsetButton: '+ Добавить гарнитуру',
  userHeadset: '(гарнитура пользователя)',
  myHeadser: 'моя гарнитруа',
  goToMeetingButton: 'Перейти в конференцию',
  dataOnHeadsetButton: 'Данные с гарнитуры',
};
