import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useInfinityGetVideo } from 'api';
import { GetVideoResponce } from 'api/endpoints/video/get-video';
import { useIntersection } from 'hooks';
import { Column } from 'components';
import { HeadsetVideoItem } from './headset-videos-item';

const LIMIT = 20;

export const HeadsetVideos = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'headset_details' });
  const { id } = useParams();
  const { data, setSize } = useInfinityGetVideo({ headsetId: id, limit: LIMIT });
  const [video, setVideo] = useState<GetVideoResponce['results']>([]);

  const columnRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLDivElement>(null);
  const isVisible = useIntersection(lastItemRef, { root: columnRef.current });

  useEffect(() => {
    if (!isVisible) return;
    setSize(state => ++state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (!data) return;

    const video: GetVideoResponce['results'] = [];
    data.forEach(item => {
      const { results } = item;
      video.push(...results);
    });
    setVideo(video);
  }, [data]);

  const display = useMemo(
    () =>
      video.map((item, index) => (
        <HeadsetVideoItem
          key={item.id}
          columnItemRef={index === video.length - 1 ? lastItemRef : undefined}
          video={item}
          setVideo={setVideo}
        />
      )),
    [video],
  );

  return (
    <Column title={t('videos_title')} columnRef={columnRef}>
      {display}
    </Column>
  );
};
