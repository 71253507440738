import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCircleCheck } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useConfirmUserRegistration } from 'api';
import style from './confirm-registration.module.scss';

export const ConfirmRegistration = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'confirm_registration' });
  const { trigger: confirmUserRegistration } = useConfirmUserRegistration();
  const { code } = useParams();
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    if (!code) return;

    const asyncEffect = async () => {
      try {
        const body = { code };
        await confirmUserRegistration(body);
        setIsConfirm(true);
      } catch (e) {
        toast.error((e as { message: string }).message);
        console.error(e);
      }
    };

    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div className={style.container}>
      <div className={style.title}>{t('title')}</div>
      {isConfirm && (
        <div className={style.content}>
          <FaCircleCheck size='82px' color='green' />
          <div className={style.text}>{t('successMessage')}</div>
        </div>
      )}
    </div>
  );
};
