import { Socket } from 'socket.io-client';
import { Arrow } from './arrow';
import style from './controls.module.scss';

type PositionProps = {
  socket: Socket | null;
};
export const Position = ({ socket }: PositionProps) => {
  return (
    <div className={style.arrowsBlock}>
      <Arrow socket={socket} id='left' />
      <Arrow socket={socket} id='up' />
      <Arrow socket={socket} id='right' />
      <Arrow socket={socket} id='down' />
    </div>
  );
};
