import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip as ReactTooltip, type ITooltip } from 'react-tooltip';
import { ID_ROOT_TOOLTIP_ELEMENT } from 'constants/constants';

const TooltipPortal = ({ children }: React.PropsWithChildren) => {
  const [rootModal, setRootModal] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const rootModal = document.getElementById(ID_ROOT_TOOLTIP_ELEMENT);
    setRootModal(rootModal);
  }, []);

  return <>{rootModal && createPortal(children, rootModal)}</>;
};

export const Tooltip = ({ place = 'bottom', ...props }: ITooltip) => {
  return (
    <TooltipPortal>
      <ReactTooltip place={place} {...props} />
    </TooltipPortal>
  );
};
