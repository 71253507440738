import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDeleteSnapshot } from 'api';
import { GetSnapshotResponse } from 'api/endpoints/snapshot/get-snapshot';
import { Tooltip } from 'components';
import { VisibleIcon, DeleteIcon } from 'assets';
import style from '../headset-details.module.scss';

type HeadsetSnapshotsItemProps = {
  snapshot: GetSnapshotResponse['results'][0];
  columnItemRef?: React.RefObject<HTMLDivElement>;
  setSnapshots: React.Dispatch<React.SetStateAction<GetSnapshotResponse['results']>>;
};

export const HeadsetSnapshotsItem: FC<HeadsetSnapshotsItemProps> = ({ snapshot, columnItemRef, setSnapshots }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'headset_details' });
  const { trigger: deleteSnapshot } = useDeleteSnapshot();
  const { created_at: title, url, id } = snapshot;

  const handleDelete = async () => {
    try {
      await deleteSnapshot({ id });
      setSnapshots(state => state.filter(item => item.id !== id));
      toast.success('Снепшот успешно удален');
    } catch (e) {
      toast.error('При удалении снепшота произошла ошибка');
      console.error(e);
    }
  };

  return (
    <div className={style.columnItem} ref={columnItemRef}>
      <div className={style.point}></div>
      <div className={style.name}>{title}</div>
      <div className={style.buttons}>
        <Tooltip id={'visible_button_title_snashot' + id} content={t('visible_button_title')} />
        <a
          className={style.button}
          data-tooltip-id={'visible_button_title_snashot' + id}
          target='noreferrer'
          href={url}
        >
          <VisibleIcon width='20px' height='20px' />
        </a>
        <Tooltip id={'delete_button_title_snashot' + id} content={t('delete_button_title')} />
        <button className={style.button} data-tooltip-id={'delete_button_title_snashot' + id} onClick={handleDelete}>
          <DeleteIcon width='20px' height='20px' />
        </button>
      </div>
    </div>
  );
};
