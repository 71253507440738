import { useEffect, useState } from 'react';
import { DeviceWidth } from 'constants/constants';

export const useGetWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowSize.width <= DeviceWidth.Mobile;

  return { ...windowSize, isMobile };
};
