import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineMicrophone } from 'react-icons/hi';
import { PiWarningCircleFill } from 'react-icons/pi';
import cn from 'classnames';
import { Tooltip } from 'components';
import style from './controls.module.scss';

type MicrophoneProps = {
  isActive: boolean;
  isHasAccess: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Microphone: FC<MicrophoneProps> = ({ isActive, isHasAccess, setIsActive }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleToggleMicrophone = () => {
    setIsActive(state => !state);
  };

  return (
    <>
      <Tooltip id='controls.mic' content={t('controls.mic', { status: isActive ? t('off') : t('on') })} />
      <button
        className={cn(style.button, { [style.button__off]: !isActive || !isHasAccess })}
        data-tooltip-id='controls.mic'
        disabled={!isHasAccess}
        onClick={handleToggleMicrophone}
      >
        {!isHasAccess && (
          <>
            <Tooltip id='micNotFound' content={t('micNotFound')} />
            <PiWarningCircleFill className={style.warning} color='#facc15' size='20px' data-tooltip-id='micNotFound' />
          </>
        )}
        <HiOutlineMicrophone size='20px' color='black' />
      </button>
    </>
  );
};
