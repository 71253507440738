import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetHeadset, useGetMe } from 'api';
import { RemoteStreams } from 'hooks/use-janus';
import { HeadsetVersion } from 'constants/constants';
import { QUERY } from 'constants/path';

type Params = {
  myStream: MediaStream | null;
  remoteStreams: RemoteStreams;
  selectStreamId: string;
};

export const useGetVisibleCameraControls = ({ myStream, remoteStreams, selectStreamId }: Params) => {
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const { data: userData } = useGetMe();
  const { data: headsetData } = useGetHeadset({ id: headsetId });
  const isMyHeadset = headsetId === userData?.headset?.id;
  const headsetStream = useMemo(
    () => Object.values(remoteStreams).find(({ display }) => display === headsetData?.key),
    [headsetData, remoteStreams],
  );

  const isVisibleCameraControls = useMemo(() => {
    if (myStream?.id === selectStreamId) {
      return isMyHeadset;
    }
    if (!headsetData) return false;
    return headsetStream?.stream?.id === selectStreamId;
  }, [selectStreamId, myStream, headsetStream, isMyHeadset, headsetData]);

  const isUserHeadset = !!headsetData?.as_user;
  const isVersionFirst = headsetData?.version === HeadsetVersion.v1_0 && !isMyHeadset;
  const isVersionSecond = headsetData?.version === HeadsetVersion.v2_0 && !isMyHeadset;
  const isVersionNewSecond = headsetData?.version === HeadsetVersion.v2_1 && !isMyHeadset;

  return {
    isVisibleCameraControls,
    headsetStream,
    isMyHeadset,
    isVersionFirst,
    isVersionSecond,
    isVersionNewSecond,
    isUserHeadset,
  };
};
