import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateUserPasswordResponse } from 'api/endpoints/user/update-user-password';
import { Modal, Button } from 'components';
import style from './update-user-password-modal.module.scss';

type UpdateUserPasswordModalProps = {
  userData: UpdateUserPasswordResponse;
  onClose: () => void;
};

export const UpdateUserPasswordModal: FC<UpdateUserPasswordModalProps> = ({ userData, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.update-user-password-modal' });
  const { password, username } = userData;

  return (
    <Modal title={t('title')} onClose={onClose}>
      <div className={style.content}>
        <div className={style.item}>
          <div>{t('login')}</div>
          <div>{username}</div>
        </div>
        <div className={style.item}>
          <div>{t('password')}</div>
          <div>{password}</div>
        </div>
        <Button classNames={{ button: style.button }} label={t('close')} onClick={onClose} />
      </div>
    </Modal>
  );
};
