import React, { useState } from 'react';
import { MdOutlineArrowDropUp } from 'react-icons/md';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import style from './video-stream.module.scss';

const STYLES = {
  up: {
    top: 0,
    right: 0,
  },
  right: {
    top: 50,
    right: 0,
    transform: 'rotate(90deg)',
  },
  down: {
    top: 50,
    right: 50,
    transform: 'rotate(180deg)',
  },
  left: {
    top: 0,
    right: 50,
    transform: 'rotate(270deg)',
  },
};

type ItemProps = {
  socket: Socket;
  position: 'up' | 'right' | 'down' | 'left';
};

const Item = ({ socket, position }: ItemProps) => {
  const [isPress, setIsPress] = useState(false);

  const handleMouseDown = () => {
    socket.emit(position, true);
    setIsPress(true);
  };

  const handleMouseUp = () => {
    socket.emit(position, false);
    setIsPress(false);
  };

  return (
    <div
      className={cn(style.item, { [style.item__active]: isPress })}
      style={STYLES[position]}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
    >
      <div className={style.itemContent}>
        <div className={style.quarterCircle}></div>
        <div className={style.arrow}>
          <MdOutlineArrowDropUp size={40} color='white' />
        </div>
      </div>
    </div>
  );
};

type PositionControlProps = {
  socket: Socket;
  isFullscreen?: boolean;
};

export const PositionControl = ({ socket, isFullscreen }: PositionControlProps) => {
  return (
    <div className={cn(style.positionControlContainer, { [style.positionControlContainer__fullscreen]: isFullscreen })}>
      <div className={style.positionControl}>
        <Item position='up' socket={socket} />
        <Item position='right' socket={socket} />
        <Item position='down' socket={socket} />
        <Item position='left' socket={socket} />
        <div className={style.horizontalLine}></div>
        <div className={style.verticalLine}></div>
      </div>
    </div>
  );
};
