import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FaAnglesDown, FaAnglesUp } from 'react-icons/fa6';
import { MdOutlineSubject, MdOutlineClose } from 'react-icons/md';
import { PhotoModal } from 'components';
import { TFile } from './bottom-block';
import style from './styles.module.scss';

type AttachmentsBlockProps = {
  files: TFile[];
  setFiles: React.Dispatch<React.SetStateAction<TFile[]>>;
};

export const AttachmentsBlock: FC<AttachmentsBlockProps> = ({ files, setFiles }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [photos, setPhotos] = useState<TFile[]>([]);
  const [documents, setDocuments] = useState<TFile[]>([]);
  const [selectSrc, setSelectSrc] = useState('');

  useEffect(() => {
    const photos: TFile[] = [];
    const documents: TFile[] = [];

    files.forEach(item => {
      const { type } = item.file;
      const indexOfFirst = type.indexOf('image/');

      if (indexOfFirst === 0) {
        photos.push(item);
      } else {
        documents.push(item);
      }
    });

    setPhotos(photos);
    setDocuments(documents);
  }, [files]);

  const handleToggleOpen = () => setIsOpen(state => !state);

  const handleDeleteFile = useCallback(
    (id: string) => {
      setFiles(state => state.filter(item => item.id !== id));
    },
    [setFiles],
  );

  const handleOpenModal = (src: string) => {
    setSelectSrc(src);
  };

  const handleCloseModal = () => {
    setSelectSrc('');
  };

  const displayPhotos = useMemo(
    () =>
      photos.map(item => {
        const { id, file } = item;
        const { name } = file;
        const src = URL.createObjectURL(file);

        return (
          <div key={id} className={style.photosItem}>
            <img className={style.img} alt={name} src={src} onClick={() => handleOpenModal(src)} />
            <button className={style.deleteButton} onClick={() => handleDeleteFile(id)}>
              <MdOutlineClose color='black' size={12} />
            </button>
          </div>
        );
      }),
    [photos, handleDeleteFile],
  );

  const displayDocuments = useMemo(
    () =>
      documents.map(item => {
        const {
          id,
          file: { name },
        } = item;

        return (
          <div key={id} className={style.ducumentsItem}>
            <div className={style.image}>
              <MdOutlineSubject color='white' />
            </div>
            <div className={style.name}>{name}</div>
            <button className={style.buttonDelete} onClick={() => handleDeleteFile(id)}>
              <MdOutlineClose color='black' />
            </button>
          </div>
        );
      }),
    [documents, handleDeleteFile],
  );

  return (
    <>
      <button className={style.toggleOpenAttachmentsBlock} onClick={handleToggleOpen}>
        {isOpen ? <FaAnglesDown size={14} color='black' /> : <FaAnglesUp size={14} color='black' />}
      </button>
      {isOpen ? (
        <div className={style.attachmentsBlock}>
          {!!photos.length && (
            <div className={style.photosBlock}>
              <div className={style.photosScrollBlock}>{displayPhotos}</div>
            </div>
          )}
          <div className={style.ducumentsBlock}>{displayDocuments}</div>
        </div>
      ) : (
        <div className={style.fakeAttachmentsBlock}></div>
      )}
      {selectSrc && <PhotoModal url={selectSrc} onClose={handleCloseModal} />}
    </>
  );
};
