import { useEffect } from 'react';

export const useScreenWakeLock = () => {
  useEffect(() => {
    //тс тупит
    //@ts-ignore
    let wakeLock: WakeLockSentinel | null = null;

    const requestWakeLock = async () => {
      try {
        //тс тупит
        //@ts-ignore
        wakeLock = await navigator.wakeLock.request('screen');
      } catch (e) {
        console.error(e);
      }
    };
    requestWakeLock();

    return () => {
      wakeLock?.release();
    };
  }, []);
};
