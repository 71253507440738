import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useInfinityGetSnapshot } from 'api';
import { GetSnapshotResponse } from 'api/endpoints/snapshot/get-snapshot';
import { useIntersection } from 'hooks';
import { Column } from 'components';
import { HeadsetSnapshotsItem } from './headset-snapshots-item';

const LIMIT = 20;

export const HeadsetSnapshots = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'headset_details' });
  const { id } = useParams();
  const { data, setSize } = useInfinityGetSnapshot({ headsetId: id, limit: LIMIT });
  const [snapshots, setSnapshots] = useState<GetSnapshotResponse['results']>([]);

  const columnRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLDivElement>(null);
  const isVisible = useIntersection(lastItemRef, { root: columnRef.current });

  useEffect(() => {
    if (!isVisible) return;
    setSize(state => ++state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (!data) return;

    const snapshots: GetSnapshotResponse['results'] = [];
    data.forEach(item => {
      const { results } = item;
      snapshots.push(...results);
    });
    setSnapshots(snapshots);
  }, [data]);

  const display = useMemo(
    () =>
      snapshots.map((item, index) => (
        <HeadsetSnapshotsItem
          key={item.id}
          columnItemRef={index === snapshots.length - 1 ? lastItemRef : undefined}
          snapshot={item}
          setSnapshots={setSnapshots}
        />
      )),
    [snapshots],
  );

  return (
    <Column title={t('snapshots_title')} columnRef={columnRef}>
      {display}
    </Column>
  );
};
