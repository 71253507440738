export const PRIVATE_CABINET_SLICE = {
  title: 'Özel Kabin',
  usersTitle: 'Kullanıcı Listesi',
  addUsersButton: '+ Kullanıcı Ekle',
  userStatus: 'Durum: {{status}}',
  activeStatus: 'aktif',
  inactiveStatus: 'devre dışı',
  updatePasswordButton: 'Şifreyi Güncelle',
  editButton: 'Düzenle',
  headsetsTitle: 'Kulaklık Listesi:',
  addHeadsetButton: '+ Kulaklık Ekle',
  userHeadset: '(kullanıcının kulaklığı)',
  myHeadser: 'kulaklığım',
  goToMeetingButton: 'Konferansa Git',
  dataOnHeadsetButton: 'Kulaklıktan Veriler',
};
