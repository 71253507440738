import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { Tooltip } from 'components';
import style from './controls.module.scss';

type FullscreenProps = {
  element: HTMLElement | null;
  isFullscreen: boolean;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Fullscreen: FC<FullscreenProps> = ({ element, isFullscreen, setIsFullScreen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleToggle = () => {
    setIsFullScreen(state => !state);

    if (!element || !document.fullscreenEnabled) return;
    if (!document.fullscreenElement) {
      element.requestFullscreen();
      setIsFullScreen(true);
    } else {
      document.exitFullscreen();
      setIsFullScreen(false);
    }
  };

  return (
    <>
      <Tooltip
        id='controls.fullscreen'
        content={t('controls.video', { status: isFullscreen ? t('wrap') : t('unwrap') })}
      />
      <button className={style.button} data-tooltip-id='controls.fullscreen' onClick={handleToggle}>
        {isFullscreen ? <MdFullscreenExit size='24px' color='black' /> : <MdFullscreen size='24px' color='black' />}
      </button>
    </>
  );
};
