export const PRIVATE_CABINET_SLICE = {
  title: '私人橱柜',
  usersTitle: '用户列表',
  addUsersButton: '+ 添加用户',
  userStatus: '状态：{{status}}',
  activeStatus: '活跃',
  inactiveStatus: '已停用',
  updatePasswordButton: '更新密码',
  editButton: '编辑',
  headsetsTitle: '耳机列表：',
  addHeadsetButton: '+ 添加耳机',
  userHeadset: '(用户的耳机)',
  myHeadser: '我的耳机',
  goToMeetingButton: '前往会议',
  dataOnHeadsetButton: '耳机数据',
};
