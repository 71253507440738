import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTranslate } from 'react-icons/bs';
import cn from 'classnames';
import { Tooltip } from 'components';
import style from './controls.module.scss';

type TranslationProps = {
  isTranslation: boolean;
  setIsTranslation: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Translation: FC<TranslationProps> = ({ isTranslation, setIsTranslation }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleToggleTranslation = () => {
    setIsTranslation(state => !state);
  };

  return (
    <>
      <Tooltip
        id='controls.translation'
        content={t('controls.translation', { status: isTranslation ? t('off') : t('on') })}
      />
      <button
        className={cn(style.button, { [style.button__translation]: isTranslation })}
        data-tooltip-id='controls.translation'
        onClick={handleToggleTranslation}
      >
        <BsTranslate size='20px' color='black' />
      </button>
    </>
  );
};
