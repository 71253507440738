import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiHandFistLight } from 'react-icons/pi';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import { Tooltip } from 'components';
import style from './controls.module.scss';

type FixProps = {
  socket: Socket | null;
};

export const Fix: FC<FixProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isFix, setIsFix] = useState(false);

  useEffect(() => {
    if (!socket) return;
    const updateState = (value: boolean) => {
      setIsFix(value);
    };
    socket.on('view_fixed', updateState);

    return () => {
      socket.off('view_fixed', updateState);
    };
  }, [socket]);

  const handleToggle = () => {
    socket?.emit('view_fixed', !isFix);
    setIsFix(!isFix);
  };

  return (
    <>
      <Tooltip id='controls.fix' content={t('controls.fix', { status: isFix ? t('off') : t('on') })} />
      <button
        className={cn(style.button, { [style.button__off]: !isFix })}
        data-tooltip-id='controls.fix'
        onClick={handleToggle}
      >
        <PiHandFistLight size='20px' color='black' />
      </button>
    </>
  );
};
