import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { RxUpdate } from 'react-icons/rx';
import cn from 'classnames';
import { ChangeUserModal, ConfirmUpdateUserPasswordModal, Tooltip } from 'components';
import { User } from 'types';
import style from '../private-cabinet.module.scss';

type UsersColumnItemProps = User & {
  columnItemRef?: React.RefObject<HTMLDivElement>;
};

export const UsersColumnItem: FC<UsersColumnItemProps> = ({ columnItemRef, ...props }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'private_cabinet' });
  const { username, is_active, id } = props;
  const [isOpenChangeUserModal, setIsOpenChangeUserModal] = useState(false);
  const [isOpenUpdateUserPasswordModal, setIsOpenUpdateUserPasswordModal] = useState(false);

  const handleOpenUpdateUserPasswordModal = () => setIsOpenUpdateUserPasswordModal(true);
  const handleCloseUpdateUserPasswordModal = () => setIsOpenUpdateUserPasswordModal(false);

  const handleOpenChangeUserModal = () => setIsOpenChangeUserModal(true);
  const handleCloseChangeUserModal = () => setIsOpenChangeUserModal(false);

  return (
    <>
      <div className={style.columnItem} ref={columnItemRef}>
        <div className={style.point}></div>
        <div>
          <div className={style.name}>{username}</div>
          <div className={cn(style.status, { [style.status__active]: is_active })}>
            {t('userStatus', { status: is_active ? t('activeStatus') : t('inactiveStatus') })}
          </div>
        </div>
        <div className={style.buttons}>
          <Tooltip id={'updatePasswordButton' + id} content={t('updatePasswordButton')} />
          <button
            className={style.button}
            data-tooltip-id={'updatePasswordButton' + id}
            onClick={handleOpenUpdateUserPasswordModal}
          >
            <RxUpdate size='20px' color='black' />
          </button>
          <Tooltip id={'editButtonUser' + id} content={t('editButton')} />
          <button className={style.button} data-tooltip-id={'editButtonUser' + id} onClick={handleOpenChangeUserModal}>
            <FiEdit size='20px' color='black' />
          </button>
        </div>
      </div>
      {isOpenUpdateUserPasswordModal && (
        <ConfirmUpdateUserPasswordModal userData={props} onClose={handleCloseUpdateUserPasswordModal} />
      )}
      {isOpenChangeUserModal && <ChangeUserModal userData={props} onClose={handleCloseChangeUserModal} />}
    </>
  );
};
