import React, { useEffect, useState } from 'react';
import { useScreenWakeLock } from 'hooks';
import { Header, VideoRoom, Preparation } from './';
import style from './meeting.module.scss';

export const Meeting = () => {
  useScreenWakeLock();
  const [isActiveMeeting, setIsActiveMeeting] = useState(false);

  const [myStream, setMyStream] = useState<MediaStream | null>(null);
  const [isActiveMicrophone, setIsActiveMicrophone] = useState(true);
  const [isActiveVideo, setIsActiveVideo] = useState(true);
  const [isHasAccessMicrophone, setIsHasAccessMicrophone] = useState(false);
  const [isHasAccessVideo, setIsHasAccessVideo] = useState(false);
  const [videoDeviceId, setVideoDeviceId] = useState('');
  const [audioDeviceId, setAudioDeviceId] = useState('');
  //TODO: костыль для демо
  //в будущем всю логику с паузой удалить и написать новую
  const [isPause, setPause] = useState(false);

  useEffect(() => {
    return () => {
      myStream?.getTracks()?.forEach(track => {
        track.stop();
      });
    };
  }, [myStream]);

  return (
    <div className={style.container}>
      <Header
        isActiveMeeting={isActiveMeeting}
        isPause={isPause}
        setIsActiveMicrophone={setIsActiveMicrophone}
        setIsActiveVideo={setIsActiveVideo}
      />
      {isActiveMeeting ? (
        <VideoRoom
          isActiveMicrophone={isActiveMicrophone}
          isActiveVideo={isActiveVideo}
          videoDeviceId={videoDeviceId}
          audioDeviceId={audioDeviceId}
          isPause={isPause}
          isHasAccessMicrophone={isHasAccessMicrophone}
          isHasAccessVideo={isHasAccessVideo}
          myStream={myStream}
          setPause={setPause}
          setIsActiveMeeting={setIsActiveMeeting}
          setIsActiveMicrophone={setIsActiveMicrophone}
          setIsActiveVideo={setIsActiveVideo}
        />
      ) : (
        <Preparation
          isActiveMicrophone={isActiveMicrophone}
          isActiveVideo={isActiveVideo}
          isHasAccessMicrophone={isHasAccessMicrophone}
          isHasAccessVideo={isHasAccessVideo}
          videoDeviceId={videoDeviceId}
          audioDeviceId={audioDeviceId}
          myStream={myStream}
          setMyStream={setMyStream}
          setVideoDeviceId={setVideoDeviceId}
          setAudioDeviceId={setAudioDeviceId}
          setIsActiveMeeting={setIsActiveMeeting}
          setIsHasAccessMicrophone={setIsHasAccessMicrophone}
          setIsHasAccessVideo={setIsHasAccessVideo}
          setIsActiveMicrophone={setIsActiveMicrophone}
          setIsActiveVideo={setIsActiveVideo}
        />
      )}
    </div>
  );
};
